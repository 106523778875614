<template>
  <div class="s-box">
    <div class="s-top">
      <el-image
        src="https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/logo.png"
      />
      <div class="s-top-menu">
        <el-anchor :offset="200" direction="horizontal">
          <el-anchor-link
            v-for="item in menuList"
            :key="item.id"
            @click="menuClick(item.id)"
          >
            {{ item.name }}
          </el-anchor-link>
        </el-anchor>
        <!-- <el-button
          v-for="item in menuList"
          :key="item.id"
          text
          @click="menuClick(item.id)"
        >
          {{ item.name }}
        </el-button> -->
      </div>
      <div class="s-stop-right">
        <el-icon @click="handleIconClick"><Menu /></el-icon>
      </div>
      <el-dialog
        v-model="drawer"
        width="90%"
        :show-close="false"
        class="s-drawer"
      >
        <el-anchor :offset="200" direction="horizontal">
          <el-anchor-link
            v-for="item in menuList"
            :key="item.id"
            @click="menuClick(item.id)"
          >
            {{ item.name }}
          </el-anchor-link>
        </el-anchor>
      </el-dialog>
    </div>
    <div class="s-bottom" v-if="activeMenu === 0">
      <el-carousel>
        <el-carousel-item v-for="item in carouselList" :key="item">
          <a :href="item.herfUrl" target="_blank">
            <el-image :src="item.imgUrl" />
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="s-bottom s-bottom-1" v-if="activeMenu === 1">
      <div class="s-bottom-1-left">
        <div
          v-for="item in button_left_list"
          :key="item.id"
          class="s-bottom-1-left-item"
          @click="buttomLeftClick(item.id)"
        >
          <el-image :src="item.urlImage" />
        </div>
      </div>
      <div class="s-bottom-1-center">
        <div
          v-if="leftButtonContent.find((item) => item.id === buttomLeftNum)"
          style="width: 100%"
        >
          <div class="s-bottom-1-center-top">
            <el-image
              :src="
                leftButtonContent.find((item) => item.id === buttomLeftNum)
                  .imgUrl
              "
            >
            </el-image>
          </div>
          <div class="s-bottom-1-center-center">
            <div class="s-bottom-1-center-center-title">
              {{
                leftButtonContent.find((item) => item.id === buttomLeftNum)
                  .title
              }}
            </div>
            <div class="s-bottom-1-center-center-subtitle">
              {{
                leftButtonContent.find((item) => item.id === buttomLeftNum)
                  .subTitle
              }}
            </div>
          </div>
          <div class="s-bottom-1-center-buttom">
            <el-button v-for="item in btnContent" :key="item.id">
              <span class="btnContent">
                {{ item.content }}
              </span>
            </el-button>
          </div>
        </div>
      </div>
      <div class="s-bottom-1-right">
        <el-image
          :src="
            leftButtonContent.find((item) => item.id === buttomLeftNum).rightImg
          "
          fit="contain"
        ></el-image>
      </div>
    </div>
    <div class="s-bottom s-bottom-2" v-if="activeMenu === 2">
      <div class="s-bottom-2-left">
        <div class="s-bottom-2-left-title">关于我们</div>
        <div class="s-bottom-2-left-subtitle">ABOUT US</div>
      </div>
      <div class="s-bottom-2-right">
        <el-image
          class="s-bottom-2-right-img"
          src="https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/intro-bg.png"
        >
        </el-image>
        <div class="s-bottom-2-right-box">
          <div class="s-bottom-2-right-box-box">
            <div class="s-bottom-2-right-box-box-title">
              厦门星梦园游戏有限公司
            </div>
            <div class="s-bottom-2-right-box-box-content">
              厦门星梦园游戏有限公司是一家网络游戏研发、运营和服务公司,于2024年成立,公司由一批资深游戏行业从业人员组成,专门从事休闲游戏的开发和运营。
            </div>
            <div class="s-bottom-2-right-box-box-content2">
              我们致力于为玩家提供新颖、有趣且简单的游戏体验,鼓励玩家间互动,丰富生活乐趣。与广大游戏爱好者一同成长至今,星梦园游戏在中国发行了《西游乐园》、《纸牌爱消除》等10余款广受玩家好评的游戏。公司秉承“创造无极限”的企业愿景,通过有限的游戏内容创造无限乐趣,不断追求技术和创意的突破,以提供更丰富、更沉浸的游戏体验。未来,星梦园将持续深耕休闲游戏领域,坚持以玩家体验为开发理念,为全球用户带来更好的游戏产品。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="s-bottom s-bottom-3" v-if="activeMenu === 3">
      <div class="s-bottom-3-bg"></div>
      <div class="s-bottom-3-left">
        <el-image
          :src="
            leftButtonContent.find((item) => item.id === buttomRightNum)
              .rightImg
          "
        ></el-image>
      </div>
      <div class="s-bottom-3-right">
        <div class="s-bottom-3-right-button">
          <el-button
            text
            v-for="item in btnChangeLeftImg"
            :key="item.id"
            @click="btnChangeLeftImgClick(item.id)"
          >
            {{ item.content }}
          </el-button>
        </div>

        <div class="s-bottom-3-right-center">
          <div
            class="s-bottom-3-right-center-haoItem"
            :class="`s-bottom-3-right-center-haoItem-${item.id}`"
            v-for="item in haoItemList"
            :key="item.id"
          >
            <span class="s-bottom-3-right-center-haoItem-content">
              {{ item.content }}
            </span>
            <el-image
              class="play-icon"
              src="	https://www.xmyanqu.com/images/recruit/play-icon.png"
              alt="播放按钮"
            />
          </div>
          <div class="s-bottom-3-right-center-buttom">
            <div class="s-bottom-3-right-center-buttom-box">
              <div class="s-bottom-3-right-center-buttom-box-toptext">
                官方招聘
              </div>
              <div class="s-bottom-3-right-center-buttom-box-centertext">
                MORE INFORMATION
              </div>
              <div class="s-bottom-3-right-center-buttom-box-buttomtext">
                立即投递
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="s-bottom s-bottom-4" v-if="activeMenu === 4">
      <div class="s-bottom-4-top">
        <div class="s-bottom-4-top-box">
          <div class="s-bottom-4-top-box-title">厦门</div>
          <div class="s-bottom-4-top-box-subtitle">HR招聘邮箱:HR@xmxmy.com</div>
          <div class="s-bottom-4-top-box-site">
            地址:福建省厦门市软件园二期望海路十号楼之二4楼
          </div>
          <div class="s-bottom-4-top-box-phone">电话:4000996888</div>
        </div>
      </div>
      <div class="s-bottom-4-buttom">
        <div class="s-bottom-4-buttom-box">
          <div class="s-bottom-4-buttom-box-left">
            <el-image
              src="https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/logo-white.png"
              fit="contain"
            />
          </div>
          <div class="s-bottom-4-buttom-box-right">
            <div class="s-bottom-4-buttom-box-right-top">
              <a
                href="https://xmxmy.com/game/terms-of-service.html"
                target="_blank"
              >
                用户协议
              </a>
              &nbsp; | &nbsp;
              <a
                href="https://xmxmy.com/game/privacy-policy.html"
                target="_blank"
              >
                隐私政策
              </a>
            </div>
            <div class="s-bottom-4-buttom-box-right-other">
              健康游戏忠告： 抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当
              适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活
            </div>
            <div class="s-bottom-4-buttom-box-right-other">
              闽ICP备2024035323号
              <el-image
                src="https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/gh.png"
              />
              闽公网安备闽公网安备35020602003026号
            </div>
            <div class="s-bottom-4-buttom-box-right-other">
              增值电信业务经营许可证 闽B2-20240499
            </div>
            <div class="s-bottom-4-buttom-box-right-other">
              适龄提示：适合18周岁以上使用。
            </div>
            <div class="s-bottom-4-buttom-box-right-other">
              Copyright © 2024 厦门星梦园游戏有限公司 版权所有
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { Menu } from "@element-plus/icons-vue";
const router = useRouter();
const activeMenu = ref(0);
const buttomLeftNum = ref(1);
const buttomRightNum = ref(1);
const menuList = [
  {
    id: 0,
    name: "首页",
  },
  {
    id: 1,
    name: "游戏产品",
  },
  {
    id: 2,
    name: "关于我们",
  },
  {
    id: 3,
    name: "人才招聘",
  },
  {
    id: 4,
    name: "联系我们",
  },
  {
    id: 5,
    name: "游戏中心",
  },
];
const carouselList = [
  {
    id: 0,
    // 切换手机端需要更换图片
    // /webImages/banner/mobileBanner-1.png
    imgUrl:
      "https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/banner/banner-1.jpg",
    herfUrl: "https://xmxmy.com/xyly/",
  },
  {
    id: 1,
    imgUrl:
      "https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/banner/banner-2.jpg",
    herfUrl: "https://xmxmy.com/fkqc/",
  },
  {
    id: 2,
    imgUrl:
      "https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/banner/banner-3.jpg",
    herfUrl: "https://xmxmy.com/dgzp/",
  },
];

const button_left_list = [
  {
    id: 1,
    urlImage:
      "https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/jwdzg/icon-role.png",
  },
  {
    id: 2,
    urlImage:
      "https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/xddq/icon-role.png",
  },
  {
    id: 3,
    urlImage:
      "https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/wmzb/icon-role.png",
  },
];
const menuClick = (id) => {
  if (id === 5) {
    router.push({ name: "game" });
  } else {
    activeMenu.value = id;
  }
  if (drawer.value) {
    drawer.value = false;
  }
  // console.log(id);
};
const leftButtonContent = [
  {
    id: 1,
    imgUrl:
      "https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/jwdzg/logo-MeleeKing.png",
    title: "一款RPG冒险游戏",
    subTitle:
      "作为被上苍选中的天龙之子，你将化身神话中的传奇人物踏上探险旅程，面对一个充满魔法、神秘和强大敌人的世界。",
    btnUrl: "https://xmxmy.com/xyly/",
    rightImg:
      "https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/jwdzg/aside-Monkey.png",
  },
  {
    id: 2,
    imgUrl:
      "https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/xddq/logo-CarJamMaster.png",
    title: "一款益智解压的停车场模拟游戏",
    subTitle:
      "轻松上手停车模拟器，在不造成任何碰撞的情况下，引导每辆车离开停车区域。",
    btnUrl: "https://xmxmy.com/fkqc/",
    rightImg:
      "https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/xddq/aside-CarJamMaster.png",
  },
  {
    id: 3,
    imgUrl:
      "https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/wmzb/logo-CuteSolitaire.png",
    title: "一款多主题的经典纸牌手游",
    subTitle:
      "可爱的主题玩法打破关于你对传统纸牌游戏的认知，给你带来不一样的纸牌体验！",
    btnUrl: "https://xmxmy.com/dgzp/",
    rightImg:
      "https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/wmzb/aside-CuteSolitaire.png",
  },
];
const btnContent = [
  { id: 0, content: "官方试玩" },
  { id: 1, content: "苹果商店" },
  { id: 2, content: "好友快爆" },
  { id: 3, content: "官方微信" },
];
const btnChangeLeftImg = [
  { id: 1, content: "人才理念" },
  { id: 2, content: "培训发展" },
  { id: 3, content: "企业福利" },
];
const buttomLeftClick = (id) => {
  buttomLeftNum.value = id;
};
const btnChangeLeftImgClick = (id) => {
  buttomRightNum.value = id;
};
const haoItemList = [
  {
    id: 1,
    content: "协作",
  },
  {
    id: 2,
    content: "成长",
  },
  {
    id: 3,
    content: "目标",
  },
  {
    id: 4,

    content: "责任",
  },
];
const drawer = ref(false);
const handleIconClick = () => {
  console.log("1");
  drawer.value = true;
};
</script>

<style lang="scss">
// 首页横屏
.s-box {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .s-top {
    width: 100%;
    height: 5rem;
    box-sizing: border-box;
    padding: 0 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    .s-top-menu {
      display: flex;
      flex-direction: column;
      .el-anchor {
        background: transparent;
        .el-anchor__list {
          .el-anchor__item {
            .el-anchor__link {
              font-size: 1.2rem;
              margin-right: 1rem;
              color: black;
            }
          }
        }
      }
    }
    .s-stop-right {
      display: none;
    }
  }
  .s-bottom {
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    overflow-y: hidden;
    .el-carousel {
      height: calc(100% - 5rem);
      margin: 5rem 0;

      .el-carousel__container {
        height: 100%;
        .el-image {
          width: 100%;
          height: 100%;
        }
        .el-carousel__indicators {
          position: relative;
          left: 50%;
          bottom: 0;
        }
      }
    }
  }
  .s-bottom-1 {
    background: url("https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/jwdzg/bg1.png")
      no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &-left {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-item {
        background: url("https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/logo-bg.png")
          no-repeat;
        background-size: 100% 100%;
        width: 130px;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
        .el-image {
          width: 110px;
          height: 110px;
        }
      }
      //  background: url("https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/xddq/bg1.png")
      //  background: url("https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/bgs/1.png")
    }
    &-center {
      width: 40%;
      margin-top: 10%;
      height: 90%;
      display: flex;
      &-top {
        height: 25%;
      }
      &-center {
        height: 25%;
        &-title {
          font-size: 2rem;
          font-weight: bold;
          margin-bottom: 1rem;
        }
        &-subtitle {
          font-size: 1rem;
          font-weight: bold;
          text-align: left;
        }
      }
      &-buttom {
        height: 50%;
        display: flex;
        .el-button {
          height: 80%;
          margin-right: 3rem;
          background: url("https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/jwdzg/item-bg.png")
            no-repeat;
          background-size: 100% 100%;
          border-radius: 10px;
          .btnContent {
            display: flex;
            flex-wrap: wrap;
            writing-mode: vertical-rl;
          }
        }
      }
    }
    &-right {
      width: 40%;
    }
  }
  .s-bottom-1-1 {
    background: url("https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/jwdzg/bg1.png")
      no-repeat !important;
    background-size: 100% 100%;
  }
  .s-bottom-1-2 {
    background: url("https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/xddq/bg1.png")
      no-repeat !important;
    background-size: 100% 100%;
  }
  .s-bottom-1-3 {
    background: url("https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/wmzb/bg1.png")
      no-repeat !important;
    background-size: 100% 100%;
  }
  .s-bottom-2 {
    background: url("https://xmxmygame.oss-ap-southeast-1.aliyuncs.com/webImages/bgs/2-1.png");
    display: flex;
    &-left {
      width: 20%;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 10%;
      &-title {
        color: #322daa;
        font-size: 2rem;
        font-weight: bold;
        width: 100%;
        text-align: right;
      }
      &-subtitle {
        font-size: 1rem;
        font-weight: bold;
        width: 100%;
        text-align: right;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 80%;
      &-img {
        position: relative;
        left: 0;
        bottom: -4rem;
      }
      &-box {
        margin: 0 auto;
        width: 70%;
        height: 90%;
        display: flex;
        &-box {
          width: 100%;
          height: 80%;
          border-radius: 10px;
          background-color: white;
          padding: 0 5rem;
          box-shadow: 0 0 3rem 0 rgba(80, 80, 80, 0.16);
          &-title {
            font-weight: bold;
            font-size: 2rem;
            margin: 5rem auto 1.2rem;
          }
          &-content,
          &-content2 {
            font-size: 1rem;
            line-height: 3rem;
            text-indent: 2rem;
            text-align: left;
            font-weight: bold;
          }
        }
      }
    }
  }
  .s-bottom-3 {
    height: 100%;
    width: 100%;
    background: url("../../assets/image/bgs/3.png");
    display: flex;
    &-bg {
      background: url("../../assets/image/recruit/bg-all.png");
      background-size: cover;
      height: 100%;
      position: relative;
      width: 100%;
      z-index: 2;
    }
    &-left {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      top: 15rem;
      left: 17rem;
      width: 35rem;
      z-index: 1;
      height: 30rem;
    }
    &-right {
      position: absolute;
      top: 10rem;
      right: 10rem;
      width: 35rem;
      z-index: 3;
      height: 35rem;
      .el-button {
        background: transparent;
        border: 0;
        font-size: 1.5rem;
        font-weight: bold;
        color: black;
        padding: 0;
        margin-right: 2rem;
      }
      &-center {
        width: 30rem;
        height: 30rem;
        background: url("../../assets/image/recruit/hao-center.png");
        background-size: cover;
        &-haoItem {
          width: 220px;
          height: 80px;
          position: absolute;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: white;
          padding: 0 10px;
          box-sizing: border-box;
          &-content {
            font-size: 1.5rem;
          }
          .play-icon {
            width: 54px;
            height: 40px;
          }
        }
        &-haoItem-1 {
          background: url("../../assets/image/recruit/hao1.png") no-repeat;
          background-size: contain;
          top: 10rem;
          left: -5rem;
        }
        &-haoItem-2 {
          background: url("../../assets/image/recruit/hao2.png") no-repeat;
          background-size: contain;
          top: 10rem;
          right: -1.3rem;
        }
        &-haoItem-3 {
          background: url("../../assets/image/recruit/hao4.png") no-repeat;
          background-size: contain;
          bottom: 10rem;
          right: 2rem;
        }
        &-haoItem-4 {
          background: url("../../assets/image/recruit/hao5.png") no-repeat;
          background-size: contain;
          bottom: 10rem;
          left: -1rem;
        }
        &-buttom {
          position: absolute;
          bottom: -8rem;
          left: -5rem;
          &-box {
            background: url("../../assets/image/recruit/btn-bg.png") no-repeat;
            background-size: contain;
            width: 20rem;
            height: 10rem;
            display: flex;
            flex-direction: column;
            padding: 1rem 1rem;
            box-sizing: border-box;
            text-align: left;
            color: #4e381d;
            &-toptext {
              font-weight: bold;
              font-size: 1.2rem;
              margin-bottom: 1rem;
            }
            &-centertext {
              font-weight: bold;
              font-size: 0.8rem;
              margin-bottom: 3rem;
            }
            &-buttomtext {
              font-size: 0.8rem;
              margin-bottom: 2rem;
            }
          }
        }
      }
    }
  }
  .s-bottom-4 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    &-top {
      background: url("../../assets/image/bgs/4.png") no-repeat;
      background-size: cover;
      height: 70%;
      &-box {
        width: 70%;
        height: 90%;
        margin: 0 auto;
        background: url("../../assets/image/contact-bg.png") no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        &-title {
          font-weight: bold;
          font-size: 3rem;
        }
        &-subtitle,
        &-site,
        &-phone {
          font-size: 1.2rem;
        }
      }
    }
    &-buttom {
      height: 30%;
      background: url("../../assets/image/footer-bg.png") no-repeat;
      background-position: 100% 100%;
      display: flex;
      align-items: center;
      &-box {
        display: flex;
        width: 100%;
        height: 100%;
        &-left {
          width: 30%;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          margin: 4rem 0;
          .el-image {
            width: 170px;
            height: 51px;
          }
        }
        &-right {
          display: flex;
          flex-direction: column;
          width: 70%;
          color: white;
          text-align: left;
          margin: 4rem 0;
          &-top {
            a {
              &:hover {
                color: #fff;
              }
            }
          }
          &-other {
            margin-top: 0.5rem;
            font-size: 0.8rem;
            .el-image {
              width: 1rem;
              height: 1rem;
            }
          }
        }
      }
    }
  }
}

// 首页竖屏
@media screen and (orientation: portrait) {
  html,
  body {
    width: 100%;
    height: 100vh;
  }
  .s-box {
    .s-top {
      padding: 0 2rem;
      justify-content: space-between;
      .s-top-menu {
        display: none;
      }
      .s-stop-right {
        margin-left: 1rem;
        display: flex;
      }
      .el-overlay {
        margin-top: 5rem !important;
      }
      .s-drawer {
        background-color: transparent !important;
        .el-dialog__header {
          display: none;
        }
        .el-dialog__body {
          background-color: transparent;
          .el-anchor {
            background-color: transparent;
          }
        }
        .el-anchor__list {
          flex-wrap: wrap;
          .el-anchor__item {
            width: 100%;
            height: 3rem;
            a {
              height: 100%;
              font-size: 1.2rem;
              font-weight: bold;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    .s-bottom {
      .s-bottom-1-center {
        .s-bottom-1-center-buttom {
          display: none;
        }
      }
      .el-carousel {
      }
    }
    .s-bottom-1 {
      margin-top: 5rem;
      flex-direction: column-reverse;
      overflow: hidden;
      &-left {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        height: 30%;
        width: 100%;
        &-item {
          width: 54px;
          height: 54px;
          margin-left: 1rem;
          margin-bottom: 0;
          .el-image {
            width: 100%;
            height: 100%;
          }
        }
      }
      &-center {
        margin-top: 1rem;
        width: 100%;
        height: 30%;
        > div {
          display: flex;
          flex-direction: column;
        }
        &-top {
          height: 4rem;
          .el-image {
            height: 4rem;
          }
        }
        &-center {
          &-title {
            font-size: 1.2rem;
            font-weight: bold;
            margin: 1rem 0;
          }
          &-subtitle {
            padding: 0 3rem;
            line-height: 1.5rem;
            box-sizing: border-box;
            font-size: 0.8rem;
            font-weight: unset;
          }
        }
      }
      &-right {
        width: 100%;
        box-sizing: border-box;
        height: 40%;

        .el-image {
          width: 50%;
        }
      }
    }
    .s-bottom-2 {
      &-left {
        display: none;
      }
      &-right {
        margin-top: 2rem;
        width: 100%;
        height: calc(100% - 5rem);
        &-box {
          width: 100%;
          &-box {
            padding: 0;
            background-color: transparent;
            box-shadow: none;
            &-title {
              font-size: 1rem;
              font-weight: bold;
            }
            &-content,
            &-content2 {
              font-size: 0.8rem;
              line-height: 1.6rem;
              padding: 0 2rem;
            }
          }
        }
      }
    }
    .s-bottom-3 {
      margin-top: 5rem;
      &-bg {
        display: none;
      }
      &-left {
        display: none;
      }
      &-right {
        background: url("../../assets/image/recruit/hao-center.png");
        background-size: cover;
        background-position: 100% 100%;
        display: flex;
        position: static;
        width: 100%;
        height: 90%;
        padding: 0 3rem;
        box-sizing: border-box;
        flex-direction: column;
        z-index: 1;
        &-button {
          height: 3rem;
          display: flex;
          justify-content: space-between;
          .el-button {
            font-size: 1.2rem;
            margin-right: 0;
          }
        }
        &-center {
          height: calc(100% - 3rem);
          background: transparent;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          &-haoItem {
            width: 100%;
            position: static;
            background-size: cover !important;
            border-radius: 1rem;
            > div {
              left: 0;
              top: 0;
            }
          }
          &-buttom {
            position: static;
            width: 80%;
            margin: 0 auto;
            &-box {
              width: 100%;
              height: auto;
              &-toptext {
                font-size: 1rem;
              }
              &-centertext {
                font-size: 0.8rem;
                margin-bottom: 0.5rem;
              }
              &-buttomtext {
                font-size: 0.8rem;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    .s-bottom-4 {
      margin-top: 5rem;
      &-top {
        &-box {
          justify-content: center;
          background: url("../../assets/image/contact-bg2.png") no-repeat;
          width: 100%;
          height: 100%;
          &-title {
            font-size: 1.4rem;
          }
          &-subtitle,
          &-site,
          &-phone {
            font-size: 0.8rem;
            line-height: 2rem;
          }
        }
      }
      &-buttom {
        &-box {
          flex-direction: column;
          &-left {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0;
            height: 30%;
            .el-image {
              height: 90%;
              width: auto;
            }
          }
          &-right {
            flex: 1;
            margin: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            > div {
              font-size: 0.5rem;
              margin: 0.1rem 0;
              text-align: center;
              padding: 0 4rem;
              .el-image {
                width: 0.5rem;
                height: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
